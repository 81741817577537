import React, { useEffect, useState } from "react";
import { SnackbarProvider } from "notistack";
import { useNavigate } from "react-router-dom";
import {
  RcvEngine,
  EngineEvent,
  ErrorCodeType,
  GrantType,
} from "@ringcentral/video-sdk";
import GlobalContext from "./store/global/context";
import { ElementContextProvider } from "./store/element";
import "./styles/index.less";
import { config } from "./utils/ringCentralCredentials";
import Router from "./routes";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";

function App() {
  const [rcvEngine, setRcvEngine] = useState<any | null>(null);
  const [isMeetingJoined, setMeetingJoined] = useState(true);
  const navigate = useNavigate();
  const { clientId, clientSecret, jwt, clientJWT } = config;

  useEffect(() => {
    const initSDK = async () => {
      if (!clientId || !clientSecret || !jwt) {
        return;
      }
      const engine = RcvEngine.create({
        clientId,
        clientSecret,
        enableDiscovery: false,
      });

      await engine.authorize({
        grantType: GrantType.JWT,
        jwt,
      });

      engine.on(EngineEvent.MEETING_JOINED, (meetingId, errorCode) => {
        if (errorCode === ErrorCodeType.ERR_OK) {
          setMeetingJoined(true);
          // if (!window.location.pathname.includes("/meeting/")) {
          //   navigate(`/meeting/${meetingId}`);
          // }
        }
      });
      engine.on(EngineEvent.MEETING_LEFT, () => {
        navigate("/", { replace: true });
      });
      setRcvEngine(engine);
    };

    initSDK();
  }, [clientId, clientJWT, clientSecret, jwt, navigate]);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider autoHideDuration={3000} preventDuplicate>
        <GlobalContext.Provider value={{ rcvEngine, isMeetingJoined }}>
          <ElementContextProvider>
            <Router />
          </ElementContextProvider>
        </GlobalContext.Provider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
  },
  typography: {
    fontSize: 12, // Default font size for all screen sizes
    body2: {
      fontSize: 12, // Font size for body2 on all screen sizes
      "@media (min-width:600px)": {
        fontSize: 15, // Font size for body2 on screens wider than 600px
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});
