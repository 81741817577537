import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { lazy } from "react";
import { MeetingContextProvider } from "./store/meeting";
const Page404 = lazy(() => import("./pages/pageNotFound/Page404"));
const Home = lazy(() => import("./pages/Home"));
const EndMeetingPage = lazy(() => import("./pages/meeting/EndMeetingPage"));
const MeetingScreens = lazy(() => import("./pages/meeting/meetingScreens"));

const Router = () => {
  const routes = useRoutes([
    {
      path: "/",
      children: [
        { path: "/", element: <Home /> },
        {
          path: "joinMeeting/:meetingID?/:passcode?/:userID?",
          element: (
            <MeetingContextProvider>
              <MeetingScreens />
            </MeetingContextProvider>
          ),
        },
        // {
        //   path: "meeting/:meetingId",
        //   element: (
        //     <MeetingContextProvider>
        //       <InMeeting />
        //     </MeetingContextProvider>
        //   ),
        // },
        {
          path: "meetingEnded",
          element: <EndMeetingPage />,
        },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
  ]);

  return routes;
};
export default Router;
