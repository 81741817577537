import React, {
  createContext,
  useContext,
  useReducer,
  PropsWithChildren,
} from "react";
import { IMeetingState, IMeetingContext } from "./types";
import { meetingReducer } from "./reducer";
/* eslint-disable-next-line padded-blocks */
const initState: IMeetingState = {
  isMeetingLocked: false,
  isAudioMuted: true,
  isVideoMuted: true,
  participantList: [],
  isModalPinned: false,
  activeFeatureModal: null,
  chatMessages: [],
  userData: {},
  isAdmin: false,
  localStreams: [],
  remoteStreams: {},
  participantMap: undefined,
  localNqiState: null,
  remoteNqiStateMap: undefined,
};
export const MeetingContext = createContext<IMeetingContext>({
  state: initState,
  dispatch: () => {},
});

export const MeetingContextProvider: React.FC<PropsWithChildren<{}>> = (
  props
) => {
  const [state, dispatch] = useReducer(meetingReducer, initState);
  return (
    /* eslint-disable-next-line padded-blocks */
    <MeetingContext.Provider value={{ state, dispatch }}>
      {props.children}
    </MeetingContext.Provider>
  );
};

const useMeetingContext = () => useContext<IMeetingContext>(MeetingContext);
export { useMeetingContext };
